import classNames from "classnames";
import { useEffect, useState } from "react";
import { NextRouter } from "next/router";
import Image from "next/image";
import useResponsive, { EScreenSize } from "@Hook/useResponsive";
import styles from "./styles.module.scss";
import ZaloIcon from "./zalo.png";
import PhoneIcon from "./phone.png";
import HoTro24On7 from "./HoTro24On7";
import TroLyMuaSam from "./TroLyMuaSam";
import BaoGiaMienPhi from "./BaoGiaMienPhi";
import LienHeNhanUuDai from "./LienHeNhanUuDai";

interface StickyRightButtonProps {
   router: NextRouter;
   className?: string;
   isMobile: boolean;
}

const StickyRightButton: React.FC<StickyRightButtonProps> = ({ router, className }) => {
   const [isShow, setIsShow] = useState<boolean>(true);
   const [isVisible, setIsVisible] = useState(true);
   const [lastScrollY, setLastScrollY] = useState(0);
   const screenSize = useResponsive();
   const isMobile = screenSize === EScreenSize.MOBILE;
   const isDesktop = screenSize === EScreenSize.DESKTOP;

   const routeTextMap: Record<string, string> = {
      "/referral-program": "Hỗ trợ 24/7",
      "/shop": "Trợ lý mua sắm từ A-Z",
      "/quy-trinh-ket-noi": "Tư vấn báo giá miễn phí, Không chi phí ẩn",
      "/noithatgiasi": "Liên hệ nhận ưu đãi, Tùy chỉnh nội thất",
   };

   const text = routeTextMap[router.pathname] || "Hỗ trợ 24/7";

   const handleClose = () => setIsShow(false);

   const handleZalo = () => {
      if (typeof window.fbq === "function") {
         window.fbq?.("track", "Contact", {
            method: "Zalo",
            action: "Button Click",
         });
      }
   };

   const handlePhone = () => {
      if (typeof window.fbq === "function") {
         window.fbq?.("track", "Contact", {
            method: "Phone",
            action: "Button Click",
         });
      }
   };

   if (router.pathname === "/shop/dat-hang" && isMobile) {
      return null;
   }

   useEffect(() => {
      const handleScroll = () => {
         const currentScrollY = window.scrollY;

         const path = router.asPath;
         const isBlogDetailPage = /^\/blog\/[^\/]+\/[^\/]+$/.test(path);

         if (isBlogDetailPage && currentScrollY > 400) {
            setIsVisible(false);
         } else {
            setIsVisible(true);
         }

         setLastScrollY(currentScrollY);
      };

      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => window.removeEventListener("scroll", handleScroll);
   }, [lastScrollY, router.asPath]);

   return (
      <div
         className={classNames(
            className,
            styles["sticky-right-button"],
            router.pathname.startsWith("/noithatgiasi") || router.pathname.startsWith("/referral-program")
               ? styles["noithatgiasi"]
               : ""
         )}
         style={!isVisible && !isDesktop ? { bottom: "110px" } : {}}
      >
         {/* Ho tro 24/7 */}
         {isShow && text === "Hỗ trợ 24/7" && (
            <div className={styles["info-general"]}>
               <HoTro24On7 handleClose={handleClose} />
            </div>
         )}

         {/* Trợ lý mua sắm từ A-Z */}
         {isShow && text === "Trợ lý mua sắm từ A-Z" && (
            <div className={styles["info-tro-ly-mua-sam"]}>
               <TroLyMuaSam handleClose={handleClose} />
            </div>
         )}

         {/* Tư vấn báo giá miễn phí, Không chi phí ẩn */}
         {isShow && text === "Tư vấn báo giá miễn phí, Không chi phí ẩn" && (
            <div className={styles["info-tu-van-bao-gia-free"]}>
               <BaoGiaMienPhi handleClose={handleClose} />
            </div>
         )}

         {/* Liên hệ nhận ưu đãi, Tùy chỉnh nội thất */}
         {isShow && text === "Liên hệ nhận ưu đãi, Tùy chỉnh nội thất" && (
            <div className={styles["info-lien-he-nhan-uu-dai"]}>
               <LienHeNhanUuDai handleClose={handleClose} />
            </div>
         )}

         <a
            className={styles["contact-icon"]}
            href="https://zalo.me/1171954959320860843"
            target="_blank"
            rel="nofollow"
         >
            <Image width={48} height={48} src={ZaloIcon.src} alt="Space T zalo" onClick={handleZalo} />
         </a>
         <a className={styles["contact-icon"]} href="tel:+84848847938" rel="nofollow">
            <Image width={48} height={48} src={PhoneIcon.src} alt="Space T phone" onClick={handlePhone} />
         </a>
      </div>
   );
};

export default StickyRightButton;
