/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { useEffect } from "react";
import { get } from "lodash";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import Image from "next/image";
import store, { RootState } from "state";
import { toggleModalAuth } from "state/action/auth";
import { EAuthType, EStep } from "@Components/modal/auth/const";
import { IMenu } from "@Models/menu.model";
import Link from "@Components/Link";
import LinkOrActionAuth from "@Components/LinkOrActionAuth";
import { appDomain } from "@Models/__variables";
import styles from "./_scss/index.module.scss";
import { EHeaderView } from "./header.types";

function HeaderMobile({
   view = EHeaderView.home,
   menus,
   drawerSearch,
   drawerMenu,
   toggleDrawerSearch,
   toggleDrawerMenu,
   onCreatePost,
}: {
   view?: EHeaderView;
   menus: IMenu;
   drawerSearch: boolean;
   drawerMenu: boolean;
   toggleDrawerMenu: any;
   toggleDrawerSearch: any;
   onCreatePost: any;
}) {
   const router = useRouter();
   const auth = useSelector((state: RootState) => state.auth);

   useEffect(() => {
      let prevScrollpos = 0;
      function handleScroll() {
         function isWhatPercentOf(x, y) {
            return (x / y) * 100;
         }
         const currentScrollPos = window.pageYOffset;
         if (window.innerWidth < 768) {
            const navElm = document.getElementById("headerv2");
            const isDown = prevScrollpos <= currentScrollPos;
            if (isDown && currentScrollPos < 50) {
               // navElm && (navElm.style.background = `transparent`);
               navElm &&
                  (navElm.style.background = `rgba(245, 245, 250, ${isWhatPercentOf(currentScrollPos, 50) / 100})`);
            } else {
               if (currentScrollPos <= 56) {
                  navElm &&
                     (navElm.style.background = `rgba(245, 245, 250, ${isWhatPercentOf(currentScrollPos, 50) / 100})`);
               } else {
                  navElm && (navElm.style.background = `rgba(245, 245, 250, 1)`);
               }
            }
         }
         prevScrollpos = currentScrollPos;
      }
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   return (
      <>
         <header className={classNames([styles["header"]], styles[router.asPath?.split("/")[1]])} id="headerv2">
            <div className={`shop-menu-mb`}>
               <div className="d-flex align-items-center">
                  <div className="collapse-icon" onClick={toggleDrawerMenu}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z" fill="#252A2B" />
                     </svg>
                  </div>
                  <div className={styles["title_header"]}>
                     <Link href="/">
                        <Image src="/assets/logo.png" width={112} height={28} alt="Space T Việt Nam" />
                     </Link>
                  </div>
               </div>
               <div className="d-flex align-items-center">
                  <button className="search-icon btn-text" style={{ padding: "4px 4px" }} onClick={toggleDrawerSearch}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                           d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z"
                           fill="#252A2B"
                        />
                     </svg>
                  </button>
                  {view === EHeaderView.home ? (
                     <>
                        {/* <button className="btn-text ms-2" style={{ padding: "4px 4px" }} onClick={toggleDrawerSearch}>
                        <a href="https://zalo.me/1171954959320860843" target="_blank" rel="noreferrer">
                           <Image width={26} height={26} src="/assets/img/icon/zalo-icon.svg" alt="chat" />
                        </a>
                     </button> */}
                        <a
                           className={classNames(
                              "shop-card pointer ms-2",
                              get(auth, "notifications.cartCount", 0) > 0 ? "isAvailableCartCount" : ""
                           )}
                           style={{ padding: "4px 4px" }}
                           onClick={() => {
                              if (!auth.user) {
                                 store.dispatch(
                                    toggleModalAuth({
                                       visible: true,
                                       type: EAuthType.signup,
                                       step: EStep.signup,
                                    })
                                 );
                              } else {
                                 router.push("/shop/gio-hang");
                              }
                           }}
                        >
                           <span className="cartCount">{get(auth, "notifications.cartCount", 0)}</span>
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                           >
                              <path
                                 d="M8 16L16.7201 15.2733C19.4486 15.046 20.0611 14.45 20.3635 11.7289L21 6M6 6H22M8 20C8 21.1046 7.10457 22 6 22C4.89543 22 4 21.1046 4 20C4 18.8954 4.89543 18 6 18C7.10457 18 8 18.8954 8 20ZM8 20H15M15 20C15 21.1046 15.8954 22 17 22C18.1046 22 19 21.1046 19 20C19 18.8954 18.1046 18 17 18C15.8954 18 15 18.8954 15 20ZM2 2H2.966C3.91068 2 4.73414 2.62459 4.96326 3.51493L7.93852 15.0765C8.08887 15.6608 7.9602 16.2797 7.58824 16.7616L6.63213 18"
                                 stroke="black"
                                 strokeWidth="1.5"
                                 strokeLinecap="round"
                              />
                           </svg>
                        </a>

                        {/* <button className="btn-text ms-1" style={{ padding: "4px 4px" }}>
                           <Link href="/shop">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                 <path
                                    d="M8 16L16.7201 15.2733C19.4486 15.046 20.0611 14.45 20.3635 11.7289L21 6M6 6H22M8 20C8 21.1046 7.10457 22 6 22C4.89543 22 4 21.1046 4 20C4 18.8954 4.89543 18 6 18C7.10457 18 8 18.8954 8 20ZM8 20H15M15 20C15 21.1046 15.8954 22 17 22C18.1046 22 19 21.1046 19 20C19 18.8954 18.1046 18 17 18C15.8954 18 15 18.8954 15 20ZM2 2H2.966C3.91068 2 4.73414 2.62459 4.96326 3.51493L7.93852 15.0765C8.08887 15.6608 7.9602 16.2797 7.58824 16.7616L6.63213 18"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                 />
                              </svg>
                           </Link>
                        </button> */}
                     </>
                  ) : view === EHeaderView.shop ? (
                     <a
                        className={classNames(
                           "shop-card pointer ms-2",
                           get(auth, "notifications.cartCount", 0) > 0 ? "isAvailableCartCount" : ""
                        )}
                        style={{ padding: "4px 4px" }}
                        onClick={() => {
                           if (!auth.user) {
                              store.dispatch(
                                 toggleModalAuth({
                                    visible: true,
                                    type: EAuthType.signup,
                                    step: EStep.signup,
                                 })
                              );
                           } else {
                              router.push("/shop/gio-hang");
                           }
                        }}
                     >
                        <span className="cartCount">{get(auth, "notifications.cartCount", 0)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <path
                              d="M4.00081 6.41408L0.757812 3.17208L2.17281 1.75708L5.41481 5.00008H20.6568C20.8127 5.00007 20.9664 5.03649 21.1057 5.10645C21.245 5.1764 21.366 5.27794 21.459 5.40298C21.5521 5.52801 21.6147 5.67306 21.6417 5.82657C21.6688 5.98007 21.6595 6.13777 21.6148 6.28708L19.2148 14.2871C19.1531 14.4932 19.0265 14.6738 18.8539 14.8023C18.6813 14.9307 18.4719 15.0001 18.2568 15.0001H6.00081V17.0001H17.0008V19.0001H5.00081C4.7356 19.0001 4.48124 18.8947 4.29371 18.7072C4.10617 18.5197 4.00081 18.2653 4.00081 18.0001V6.41408ZM5.50081 23.0001C5.10299 23.0001 4.72146 22.842 4.44015 22.5607C4.15885 22.2794 4.00081 21.8979 4.00081 21.5001C4.00081 21.1023 4.15885 20.7207 4.44015 20.4394C4.72146 20.1581 5.10299 20.0001 5.50081 20.0001C5.89864 20.0001 6.28017 20.1581 6.56147 20.4394C6.84278 20.7207 7.00081 21.1023 7.00081 21.5001C7.00081 21.8979 6.84278 22.2794 6.56147 22.5607C6.28017 22.842 5.89864 23.0001 5.50081 23.0001ZM17.5008 23.0001C17.103 23.0001 16.7215 22.842 16.4402 22.5607C16.1588 22.2794 16.0008 21.8979 16.0008 21.5001C16.0008 21.1023 16.1588 20.7207 16.4402 20.4394C16.7215 20.1581 17.103 20.0001 17.5008 20.0001C17.8986 20.0001 18.2802 20.1581 18.5615 20.4394C18.8428 20.7207 19.0008 21.1023 19.0008 21.5001C19.0008 21.8979 18.8428 22.2794 18.5615 22.5607C18.2802 22.842 17.8986 23.0001 17.5008 23.0001Z"
                              fill="#2F2B43"
                           />
                        </svg>
                     </a>
                  ) : view === EHeaderView.feed ? (
                     <button
                        className="btn-text ms-2"
                        style={{ padding: "4px 4px" }}
                        onClick={() => {
                           if (!auth.user) {
                              store.dispatch(
                                 toggleModalAuth({
                                    visible: true,
                                    type: EAuthType.signup,
                                    step: EStep.signup,
                                 })
                              );
                           } else {
                              onCreatePost?.();
                           }
                        }}
                     >
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                           <rect width="28" height="28" rx="14" fill="#FF6347" />
                           <path
                              d="M20.6868 12.4151C21.4121 12.4151 22 13.003 22 13.7283C22 14.4536 21.4121 15.0415 20.6868 15.0415H7.31321C6.58794 15.0415 6 14.4536 6 13.7283C6 13.003 6.58794 12.4151 7.31321 12.4151H20.6868ZM14.008 6C14.827 6 15.491 6.66395 15.491 7.48297V20.517C15.491 21.3361 14.827 22 14.008 22C13.189 22 12.5251 21.3361 12.5251 20.517V7.48297C12.5251 6.66395 13.189 6 14.008 6Z"
                              fill="white"
                           />
                        </svg>
                     </button>
                  ) : view === EHeaderView.construction ? (
                     <>
                        {/* Bookmark */}
                        <LinkOrActionAuth
                           isLogin={Boolean(auth?.user?._id)}
                           href={`/tai-khoan/${auth?.user?._id}?typePost=luu-tru`}
                           title="Tài khoản"
                           onClick={() => {
                              if (!auth.user) {
                                 store.dispatch(
                                    toggleModalAuth({
                                       visible: true,
                                       type: EAuthType.signup,
                                       step: EStep.signup,
                                    })
                                 );
                              }
                           }}
                        >
                           <button className="btn-text ms-1" style={{ padding: "4px 4px" }}>
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                                 fill="none"
                              >
                                 <path
                                    d="M4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V22.276C21.0001 22.3594 20.9793 22.4416 20.9395 22.5149C20.8997 22.5882 20.8422 22.6505 20.7722 22.6959C20.7023 22.7413 20.622 22.7685 20.5388 22.775C20.4557 22.7815 20.3722 22.767 20.296 22.733L12 19.03L3.704 22.732C3.6279 22.766 3.54451 22.7805 3.46141 22.774C3.37831 22.7676 3.29813 22.7405 3.22818 22.6952C3.15822 22.6499 3.1007 22.5878 3.06085 22.5146C3.021 22.4414 3.00008 22.3593 3 22.276V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2ZM12 13.5L14.939 15.045L14.378 11.773L16.755 9.455L13.469 8.977L12 6L10.53 8.977L7.245 9.455L9.622 11.773L9.062 15.045L12 13.5Z"
                                    fill="#2F2B43"
                                 />
                              </svg>
                           </button>
                        </LinkOrActionAuth>
                        {/* Zalo */}
                        {/* <button className="btn-text ms-1" style={{ padding: "4px 4px" }}>
                           <a href="https://zalo.me/1171954959320860843" target="_blank" rel="noreferrer">
                              <Image width={24} height={24} src="/assets/img/icon/zalo-icon.svg" alt="chat" />
                           </a>
                        </button> */}
                        <a
                           className="ms-1"
                           title="Kết nối chuyên gia"
                           id="TuvanOnline"
                           rel="nofollow"
                           href={`/cau-hoi-khao-sat?get_consult=${appDomain}/quy-trinh-ket-noi`}
                        >
                           <button className={classNames("btn-advise", "ms-1")}>Tư vấn</button>
                        </a>
                     </>
                  ) : undefined}
               </div>
            </div>
         </header>
      </>
   );
}
export default HeaderMobile;
