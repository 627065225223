/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { logserver } from "utils/log";
import { apiDomain, tokenKey } from "./__variables";
import Brand from "./brand.model";
import { ArgumentTypes } from "./commons";
import { FlashSale } from "./flashSale.model";
import FlashSalesProduct from "./flashSalesProduct.model";

/**
 * Core(s)
 */
import Model, { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";

/**
 * Model(s)
 */
import ProductImage from "./productImage.model";
import ProductOption from "./productOption.model";
import ProductType from "./productType.model";
import ProductVariant from "./productVariant.model";
import UserBookmark, { IUserBookmark } from "./userBookmark.model";
import Vendor, { IVendor } from "./vendor.model";
import { fetchWithTimeout, handleSSRResponseError } from "@Utils/index";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
   draft = "draft",
}
interface ICategory {
   info?: {
      name: string;
      slug: string;
      description: string;
      content: string;
   };
   options?: {
      ["status"]?: EStatus;
   };
   _id?: string;
}
export enum EStockStatus {
   Stocking = "stocking",
   OutStock = "outstock",
   Unknown = "unknown",
}
export enum EStarCounter {
   one = "one",
   two = "two",
   three = "three",
   four = "four",
   five = "five",
}

export interface IProduct extends Model {
   ["info"]?: {
      ["title"]?: string | null;
      ["description"]?: string | null;
      ["content"]?: string | null;
      ["slug"]?: string | null;
      ["publishedAt"]?: Date | null;
      ["imageFeatured"]?: string | null;
      ["material"]?: string | null;
      ["size"]?: string | null;
      ["weight"]?: number | null;
      ["orderCount"]?: number;
      // ["priceSmallest"]: number;
      // ["priceLargest"]: number
      // ["priceAfterDiscountSmallest"]: number;
      // ["priceAfterDiscountLargest"]: number;
   };
   ["totalQuantity"]?: number | null;
   ["options"]?: {
      ["status"]?: EStatus;
      ["stockStatus"]?: EStockStatus;
   };
   ["delivery"]?: {
      ["sendFrom"]: string | null;
      ["origin"]?: string | null;
      ["deliveryFee"]?: string | null;
      ["warrantPeriod"]?: string | null;
      ["estDelivery"]?: string | null;
      ["returnTime"]?: string | null;
      ["isWarrant"]?: boolean | null;
      ["returnPolicy"]: string | null;
   };
   ["typeId"]?: string | null;
   ["vendorId"]?: string | null;
   ["brandId"]: string;
   ["categoryIds"]?: string[];
   ["keywordIds"]?: string[];
   ["isTaggingCampaign"]?: boolean;
   ["isStocking"]?: boolean;
   ["sellPriceSmallest"]: number;
   ["sellPriceLargest"]: number;
   ["originPriceSmallest"]: number;
   ["originPriceLargest"]: number;
   ["isSalesLabel"]?: boolean;

   ["__type"]?: ProductType | null;
   ["__vendor"]?: Vendor | null;
   ["__brand"]: Brand | null;
   ["__images"]?: ProductImage[];
   ["__variant"]?: ProductVariant;
   ["__variants"]?: ProductVariant[];
   ["__options"]?: ProductOption[];
   // ["__categories"]?: ProductCategory[];
   // ["__keywords"]?: Keyword[];
   ["__salesCount"]: number;

   ["__variantCount"]?: number;
   ["__imageCount"]?: number;
   ["__optionCount"]?: number;
   ["__currentUserBookmark"]?: UserBookmark;
   ["_isBookmark"]?: boolean;
   ["__currentFlashSales"]?: FlashSalesProduct;
}

export class Product extends Model<IProduct> implements IProduct {
   public ["info"]!: {
      ["title"]?: string;
      ["description"]?: string | null;
      ["content"]?: string | null;
      ["slug"]?: string | null;
      ["publishedAt"]?: Date | null;
      ["imageFeatured"]?: string | null;
      ["material"]?: string | null;
      ["size"]?: string | null;
      ["weight"]?: number | null;
      ["orderCount"]?: number;
      ["priceDisplaySmallest"]?: number;
      // ["priceSmallest"]: number;
      // ["priceLargest"]: number
      // ["priceAfterDiscountSmallest"]: number;
      // ["priceAfterDiscountLargest"]: number;
   };
   public ["totalQuantity"]: number | null = null;
   public ["options"]!: {
      ["status"]?: EStatus;
      ["stockStatus"]?: EStockStatus;
   };
   public ["delivery"]!: {
      ["sendFrom"]: string | null;
      ["origin"]: string | null;
      ["deliveryFee"]: string | null;
      ["warrantPeriod"]: string | null;
      ["estDelivery"]: string | null;
      ["returnTime"]: string | null;
      ["isWarrant"]?: boolean | null;
      ["returnPolicy"]: string | null;
   };
   public ["typeId"]!: string | null;
   public ["vendorId"]!: string | null;
   public ["brandId"]: string;
   public ["categoryIds"]!: string[];
   public ["keywordIds"]!: string[];
   public ["isTaggingCampaign"]?: boolean;
   public ["isStocking"]?: boolean;

   public ["sellPriceSmallest"]: number;
   public ["sellPriceLargest"]: number;
   public ["originPriceSmallest"]: number;
   public ["originPriceLargest"]: number;
   public ["isSalesLabel"]?: boolean;

   public ["__type"]!: ProductType | null;
   public ["__vendor"]!: Vendor | null;
   public ["__brand"]: Brand;
   public ["__images"]?: ProductImage[];
   public ["__variant"]?: ProductVariant;
   public ["__variants"]?: ProductVariant[];
   public ["__options"]!: ProductOption[];
   // ["__categories"]!: ProductCategory[];
   // ["__keywords"]!: Keyword[];
   public ["__salesCount"]: number;

   public ["__variantCount"]!: number;
   public ["__imageCount"]!: number;
   public ["__optionCount"]!: number;
   public ["__currentUserBookmark"]?: UserBookmark;
   public ["_isBookmark"]?: boolean;

   public ["__currentFlashSales"]?: FlashSalesProduct;

   constructor(data?: IProduct) {
      super();

      Object.assign(this, data);

      if (this.__images) {
         this.__images = this.__images.map((imageRawData) => new ProductImage(imageRawData));
      }

      if (this.__variants) {
         this.__variants = this.__variants.map((variantRawData) => new ProductVariant(variantRawData));
      }

      if (this.__type) {
         this.__type = new ProductType(this.__type);
      }
      if (this.__options) {
         this.__options = this.__options.map((option) => new ProductOption(option));
      }

      if (this.__vendor) {
         this.__vendor = new Vendor(this.__vendor);
      }
      if (this.__brand) {
         this.__brand = new Brand(this.__brand);
      }
      if (this.__currentUserBookmark) {
         this.__currentUserBookmark = new UserBookmark(this.__currentUserBookmark);
         this._isBookmark = !!this.__currentUserBookmark;
      }
   }

   public setNewBookmark(bookmark?: UserBookmark) {
      this.__currentUserBookmark = bookmark;
      this._isBookmark = !!bookmark;
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async paginate({
      userIp,
      cookiesToken,
      keywords,
      types,
      page,
      limit,
      offset,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      sizes,
      cateIds,
      cateSlug,
      colors,
      groupIds,
      vendorIds,
      brandSlugs,
   }: {
      userIp?: string;
      cookiesToken?: string;
      types?: string;
      keywords?: string;
      page?: number;
      limit?: number;
      offset?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "price" | "info.viewCount";
      fromPrice?: number;
      toPrice?: number;
      sizes?: string;
      cateIds?: string[];
      cateSlug?: string;
      colors?: string;
      groupIds?: string;
      vendorIds?: string;
      brandSlugs?: string[];
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;

         const response = await fetchWithTimeout(`${apiDomain}/www/product/paginate?offcontent=true`, {
            method: "GET",
            headers: {
               Origin: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               ...(!userIp ? {} : { "x-secret-for": userIp }),
               page: !page ? "1" : String(page),
               limit: !limit ? "20" : String(limit),
               sort: !sort ? "desc" : sort,
               "sort-by": !sortBy ? "info.viewCount" : sortBy,
               ...(!lastToken ? {} : { Authorization: `Bearer ${lastToken}` }),
               ...(!cateIds ? {} : { ids: encodeURI(cateIds.join(",")) }),
               ...(!cateSlug ? {} : { "category-slugs": encodeURI(cateSlug) }),
               ...(!offset ? {} : { offset: encodeURI(offset.toString()) }),
               ...(!keywords ? {} : { keywords: encodeURI(keywords) }),
               ...(!types ? {} : { types: encodeURI(types) }),
               ...(!fromPrice ? {} : { "from-price": String(fromPrice) }),
               ...(!toPrice ? {} : { "to-price": String(toPrice) }),
               ...(!sizes ? {} : { sizes: encodeURI(sizes) }),
               ...(!colors ? {} : { colors: encodeURI(colors) }),
               ...(!groupIds ? {} : { "group-ids": encodeURI(groupIds) }),
               ...(!vendorIds ? {} : { "brand-ids": encodeURI(vendorIds) }),
               ...(!brandSlugs ? {} : { "brand-slugs": brandSlugs.join(",") }),
            },
         });

         // Xử lý lỗi API nếu có
         await handleSSRResponseError(response);

         const payloadText = await response.text();
         if (!payloadText) {
            throw new ModelError({
               message: "API trả về dữ liệu rỗng.",
               httpCode: 500,
            });
         }

         let payload: TPayload<{
            paginator: TPaginate<IProduct>;
            userBookmarks: IUserBookmark[];
            groups: any[];
         }>;

         try {
            payload = JSON.parse(payloadText) as TPayload<{
               paginator: TPaginate<IProduct>;
               userBookmarks: IUserBookmark[];
               groups: any[];
            }>;
         } catch {
            throw new ModelError({
               message: "API trả về dữ liệu JSON không hợp lệ.",
               httpCode: 500,
            });
         }

         if (!payload?.data) {
            throw new ModelError({
               message: "Thiếu dữ liệu 'data' trong phản hồi API.",
               httpCode: 500,
            });
         }

         return {
            paginate: new Paginate(payload.data.paginator, Product),
            groups: payload.data.groups ?? [],
            userBookmarks: payload.data.userBookmarks ?? [],
         };
      } catch (error: any) {
         console.log(error);
         return new ModelError(error as TModelError);
      }
   }

   /**
    * Retrieves paginated products based on specified conditions.
    * @param {string} [cookiesToken] - Token used for authentication.
    * @param {string} [keywords] - Keywords to filter the products.
    * @param {string} [types] - Types of products to filter.
    * @param {number} [page] - Page number for pagination.
    * @param {number} [limit] - Number of items per page.
    * @param {number} [offset] - Number of items to skip before starting to collect the result set.
    * @param {"asc" | "desc"} [sort] - Sort order.
    * @param {"createdAt" | "price" | "info.viewCount"} [sortBy] - Attribute to sort by.
    * @param {number} [fromPrice] - Minimum price to filter the products.
    * @param {number} [toPrice] - Maximum price to filter the products.
    * @param {string} [sizes] - Sizes of products to filter.
    * @param {string[]} [cateIds] - Category IDs to filter the products.
    * @param {string} [colors] - Colors of products to filter.
    * @param {string} [groupIds] - Group IDs to filter the products.
    * @param {string} [vendorIds] - Vendor IDs to filter the products.
    * @param {string[]} [brandSlugs] - Brand slugs to filter the products.
    * @param {string} [captchat] - Captcha token for additional security.
    * @returns {Promise<any>} - A promise that resolves to the paginated products.
    */
   public static async paginatev2({
      cookiesToken,
      keywords,
      types,
      page,
      limit,
      offset,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      sizes,
      cateIds,
      colors,
      groupIds,
      vendorIds,
      brandSlugs,
      captchat,
   }: {
      cookiesToken?: string;
      types?: string;
      keywords?: string;
      page?: number;
      limit?: number;
      offset?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "price" | "info.viewCount";
      fromPrice?: number;
      toPrice?: number;
      sizes?: string;
      cateIds?: string[];
      colors?: string;
      groupIds?: string;
      vendorIds?: string;
      brandSlugs?: string[];
      captchat: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/product/paginate/v2?offcontent=true`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: !sort ? "desc" : sort,
               ["sort-by"]: !sortBy ? "info.viewCount" : sortBy,
               ["captchat"]: captchat,
               ...(!cateIds ? undefined : { ["ids"]: encodeURI(cateIds.join(",")) }),
               ...(!offset ? undefined : { ["offset"]: encodeURI(offset.toString()) }),
               ...(!keywords ? undefined : { ["keywords"]: encodeURI(keywords) }),
               ...(!types ? undefined : { ["types"]: encodeURI(types) }),
               ...(!fromPrice ? undefined : { ["from-price"]: String(fromPrice) }),
               ...(!toPrice ? undefined : { ["to-price"]: String(toPrice) }),
               ...(!sizes ? undefined : { ["sizes"]: encodeURI(sizes) }),
               ...(!colors ? undefined : { ["colors"]: encodeURI(colors) }),
               ...(!groupIds ? undefined : { ["group-ids"]: encodeURI(groupIds) }),
               ...(!vendorIds ? undefined : { ["brand-ids"]: encodeURI(vendorIds) }),
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(!brandSlugs ? undefined : { ["brand-slugs"]: brandSlugs.join(",") }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IProduct>;
            ["userBookmarks"]: IUserBookmark[];
            ["groups"]: any[];
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Product);

         return {
            paginate,
            groups: payload.data.groups,
            userBookmarks: payload.data?.userBookmarks ?? [],
         };
      } catch (error) {}
   }
   /**
    * Get product paginate for brand day
    * @param keywords
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async paginateBrandDay({
      userIp,
      cookiesToken,
      keywords,
      types,
      page,
      limit,
      offset,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      sizes,
      cateIds,
      cateSlug,
      colors,
      groupIds,
      vendorIds,
      brandSlugs,
   }: {
      userIp?: string;
      cookiesToken?: string;
      types?: string;
      keywords?: string;
      page?: number;
      limit?: number;
      offset?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "price" | "info.viewCount";
      fromPrice?: number;
      toPrice?: number;
      sizes?: string;
      cateIds?: string[];
      cateSlug?: string;
      colors?: string;
      groupIds?: string;
      vendorIds?: string;
      brandSlugs?: string[];
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/product/paginate/brand-day?offcontent=true`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!userIp ? undefined : { ["x-secret-for"]: userIp }),

               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: !sort ? "desc" : sort,
               ["sort-by"]: !sortBy ? "info.viewCount" : sortBy,
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(!cateIds ? undefined : { ["ids"]: encodeURI(cateIds.join(",")) }),
               ...(!cateSlug ? undefined : { ["category-slugs"]: encodeURI(cateSlug) }),
               ...(!offset ? undefined : { ["offset"]: encodeURI(offset.toString()) }),
               ...(!keywords ? undefined : { ["keywords"]: encodeURI(keywords) }),
               ...(!types ? undefined : { ["types"]: encodeURI(types) }),
               ...(!fromPrice ? undefined : { ["from-price"]: String(fromPrice) }),
               ...(!toPrice ? undefined : { ["to-price"]: String(toPrice) }),
               ...(!sizes ? undefined : { ["sizes"]: encodeURI(sizes) }),
               ...(!colors ? undefined : { ["colors"]: encodeURI(colors) }),
               ...(!groupIds ? undefined : { ["group-ids"]: encodeURI(groupIds) }),
               ...(!vendorIds ? undefined : { ["brand-ids"]: encodeURI(vendorIds) }),
               ...(!brandSlugs ? undefined : { ["brand-slugs"]: brandSlugs.join(",") }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IProduct>;
            ["userBookmarks"]: IUserBookmark[];
            ["groups"]: any[];
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Product);

         return {
            paginate,
            groups: payload.data.groups,
            userBookmarks: payload.data?.userBookmarks ?? [],
         };
      } catch (error) {}
   }
   /**
    * Paginate Products new
    * @param params
    * @returns
    */
   public static async paginateProductsNew(params: ArgumentTypes<typeof Product.paginate>[0]) {
      return Product.paginate({
         ...params,
         groupIds: "hang-moi",
      });
   }

   /**
    * Paginate Products new
    * @param params
    * @returns
    */
   public static async paginateProductsBestSeller(params: ArgumentTypes<typeof Product.paginate>[0]) {
      return Product.paginate({
         ...params,
         groupIds: "san-pham-ban-chay",
      });
   }
   /**
    * Get product paginate suffle by condition(s)
    * @param keywords
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async suffle({
      cookiesToken,
      keywords,
      types,
      page,
      limit,
      offset,
      sort,
      sortBy,
      exclusiveIds,
   }: {
      cookiesToken?: string;
      types?: string;
      keywords?: string;
      page?: number;
      limit?: number;
      offset?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "price" | "info.viewCount";
      exclusiveIds?: string[];
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/product/paginate/suffle?offcontent=true`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: !sort ? "desc" : sort,
               ["sort-by"]: !sortBy ? "info.viewCount" : sortBy,
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(!offset ? undefined : { ["offset"]: encodeURI(offset.toString()) }),
               ...(!keywords ? undefined : { ["keywords"]: encodeURI(keywords) }),
               ...(!types ? undefined : { ["types"]: encodeURI(types) }),
               ...(!exclusiveIds
                  ? undefined
                  : {
                       ["exclusive-ids"]: encodeURI(exclusiveIds.toString()),
                    }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IProduct>;
            ["ids"]: string;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Product);

         return { paginate, ids: payload.data.ids?.split(",") };
      } catch (error) {}
   }

   /**
    * Get product paginate by condition(s)
    * @param keywords
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async getPaginateByMenuItem({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      sizes,
      colors,
      menuIds,
      vendorIds,
      vendorSlug,
   }: {
      ["keywords"]?: string;
      ["page"]?: number;
      ["limit"]?: number;
      ["sort"]?: "asc" | "desc";
      ["sortBy"]?: "createdAt" | "price";
      ["fromPrice"]?: number;
      ["toPrice"]?: number;
      ["sizes"]?: string;
      ["colors"]?: string[] | string;
      ["menuIds"]?: string[];
      ["vendorIds"]?: string[];
      ["vendorSlug"]?: string[];
   }) {
      try {
         const response = await fetch(`${apiDomain}/www/product/paginate/byMenuItem`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: !sort ? "desc" : sort,
               ["sort-by"]: !sortBy ? "createdAt" : sortBy,
               ...(!keywords ? undefined : { ["keywords"]: encodeURI(keywords) }),
               ...(!fromPrice ? undefined : { ["from-price"]: String(fromPrice) }),
               ...(!toPrice ? undefined : { ["to-price"]: String(toPrice) }),
               ...(!sizes ? undefined : { ["sizes"]: encodeURI(sizes) }),
               ...(!colors ? undefined : { ["colors"]: encodeURI(colors.toString()) }),
               ...(!menuIds ? undefined : { ["ids"]: encodeURI(menuIds.toString()) }),
               ...(!vendorIds ? undefined : { ["vendor-slug"]: encodeURI(vendorIds.toString()) }),
               ...(!vendorSlug ? undefined : { ["vendor-slug"]: encodeURI(vendorSlug.toString()) }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IProduct>;
            ["vendors"]: IVendor[];
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Product);

         return {
            paginate,
            ["vendors"]: payload.data.vendors.map((vendorJSON) => new Vendor(vendorJSON)),
         };
      } catch (error) {}
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async getPaginateByMenuItemV2({
      token,
      keywords,
      page,
      limit,
      sort,
      sortBy,
      fromPrice,
      toPrice,
      sizes,
      colors,
      ids,
      brandIds,
      brandSlugs,
      status,
   }: {
      ["token"]?: string;
      ["keywords"]?: string;
      ["page"]?: number;
      ["limit"]?: number;
      ["sort"]?: "asc" | "desc";
      ["sortBy"]?: "createdAt" | "price" | "info.viewCount";
      ["fromPrice"]?: number;
      ["toPrice"]?: number;
      ["sizes"]?: string[];
      ["colors"]?: string[] | string;
      ["ids"]?: string[];
      ["brandIds"]?: string[];
      ["brandSlugs"]?: string[] | string;
      ["status"]?: "stocking" | "outstock";
   }) {
      try {
         const response = (await this.fetch({
            ["token"]: token,
            ["endPoint"]: "product/paginate/byMenuItem/v2?offcontent=true",
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: !sort ? "desc" : sort,
               ["sort-by"]: !sortBy ? "info.viewCount" : sortBy,
               ...(!status ? undefined : { ["status"]: status }),
               ...(!keywords ? undefined : { ["keywords"]: encodeURI(keywords) }),
               ...(!fromPrice ? undefined : { ["from-price"]: String(fromPrice) }),
               ...(!toPrice ? undefined : { ["to-price"]: String(toPrice) }),
               ...(!sizes ? undefined : { ["sizes"]: encodeURI(sizes.toString()) }),
               ...(!colors ? undefined : { ["colors"]: encodeURI(colors.toString()) }),
               ...(!ids ? undefined : { ["ids"]: encodeURI(ids.toString()) }),
               ...(!brandIds ? undefined : { ["brand-ids"]: encodeURI(brandIds.toString()) }),
               ...(!brandSlugs ? undefined : { ["brand-slugs"]: encodeURI(brandSlugs.toString()) }),
            },
         })) as TPayload<{ paginator: TPaginate<IProduct>; vendors: IVendor[] }>;

         // Kiểm tra nếu response là ModelError
         if (response instanceof ModelError) {
            return response; // Trả về lỗi nếu có
         }

         // Kiểm tra dữ liệu trả về có hợp lệ không
         if (!response?.data?.paginator) {
            throw new ModelError({
               httpCode: 500,
               message: "Invalid response structure: Missing 'paginator'.",
               errors: {},
            });
         }

         return {
            paginate: new Paginate(response.data.paginator, Product),
         };
      } catch (error) {}
   }

   /**
    * Tagging campaign
    * @param param0
    * @returns
    */
   public static async taggingCampaign({
      token,
      page,
      limit,
      sort,
      sortBy,
      cateIds,
      fromPrice,
      toPrice,
      colors,
      brandSlugs,
   }: {
      token?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "price" | "info.viewCount";
      ids?: string[];
      slug?: string;
      cateIds?: string[];
      fromPrice?: number;
      toPrice?: number;
      colors?: string;
      brandSlugs?: string[];
   }) {
      try {
         const cacheToken = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cacheToken || token;
         const response = await fetch(`${apiDomain}/www/product/paginate/tagging-campaign`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: !sort ? "desc" : sort,
               ...(!sortBy ? undefined : { ["sort-by"]: sortBy }),
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(!cateIds ? undefined : { ["ids"]: encodeURI(cateIds.join(",")) }),
               ...(!fromPrice ? undefined : { ["from-price"]: String(fromPrice) }),
               ...(!toPrice ? undefined : { ["to-price"]: String(toPrice) }),
               ...(!colors ? undefined : { ["colors"]: encodeURI(colors) }),
               ...(!brandSlugs ? undefined : { ["brand-slugs"]: encodeURI(brandSlugs.toString()) }),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IProduct>;
         }>;

         const paginate = new Paginate(payload?.data?.paginator, Product);
         return { paginate };
      } catch (error) {}
   }

   /**
    * Get product by ID
    * @param id
    * @returns
    */
   public static async getProductById(id: string) {
      try {
         const response = await fetch(`${apiDomain}/www/product`, {
            ["method"]: "GET",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["id"]: id,
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const rawText = await response.text();
         let payload;
         try {
            if (rawText.trim().startsWith("<")) {
            }
            payload = JSON.parse(rawText) as TPayload<{ product: IProduct }>;
         } catch (parseError) {
            return new ModelError({
               httpCode: 500,
               message: "Invalid response",
               errors: {
                  process: [
                     {
                        code: "process.error.5001",
                        message: "Failed to parse server response",
                     },
                  ],
               },
            });
         }

         return new Product(payload.data.product);
      } catch (error) {}
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async getProductBySlug(slug: string, cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetchWithTimeout(`${apiDomain}/www/product/${slug}.html`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["slug"]: slug,
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
            },
         });

         // Kiểm tra lỗi HTTP thông qua handleSSRResponseError
         await handleSSRResponseError(response);

         // Đọc response dưới dạng text trước khi parse JSON
         const rawText = await response.text();

         if (!rawText.trim()) {
            throw new ModelError({ message: "Empty response from server.", httpCode: 500 });
         }

         if (rawText.trim().startsWith("<")) {
            throw new ModelError({ message: `Unexpected HTML response: ${rawText.slice(0, 100)}...`, httpCode: 500 });
         }

         let payload: TPayload<{
            product: IProduct;
            averageRating: number;
            starCounter: EStarCounter;
            bookmark?: IUserBookmark;
         }>;

         try {
            payload = JSON.parse(rawText);
         } catch {
            throw new ModelError({ message: `Invalid JSON response: ${rawText}`, httpCode: 500 });
         }

         if (!payload?.data?.product) {
            throw new ModelError({ message: "Invalid response structure: Missing 'product'.", httpCode: 500 });
         }

         return {
            product: new Product(payload.data.product),
            averageRating: payload.data.averageRating,
            starCounter: payload.data.starCounter,
            bookmark: payload.data.bookmark ? new UserBookmark(payload.data.bookmark) : null,
         };
      } catch (error) {}
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async getContentOfCateBySlug(slug: string, cookiesToken?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetchWithTimeout(`${apiDomain}/www/product/category/by-menu-slug`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["slug"]: slug,
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
            },
         });

         // Kiểm tra lỗi HTTP thông qua handleSSRResponseError
         await handleSSRResponseError(response);

         // Đọc response dưới dạng text trước khi parse JSON
         const rawText = await response.text();

         if (!rawText.trim()) {
            throw new ModelError({ message: "Empty response from server.", httpCode: 500 });
         }

         let payload: TPayload<{ productCategory: ICategory }>;

         try {
            payload = JSON.parse(rawText);
         } catch {
            throw new ModelError({ message: `Invalid JSON response: ${rawText}`, httpCode: 500 });
         }

         if (!payload?.data?.productCategory) {
            throw new ModelError({ message: "Invalid response structure: Missing 'productCategory'.", httpCode: 500 });
         }

         return {
            productCategory: payload.data.productCategory,
         };
      } catch (error) {}
   }

   /**
    * Get all product images
    * @param sortBy
    * @param renew
    * @returns
    */
   public async getImages(sortBy: "info.position" = "info.position", renew: boolean = false) {
      try {
         if (!renew && this.__images) {
            return this.__images;
         }

         if (!this._id) {
            return new ModelError({
               ["httpCode"]: 400,
               ["message"]: "Product instance only exists on client.",
               ["errors"]: {
                  ["_id"]: [
                     {
                        ["code"]: "product.getImages._id.notFound",
                        ["message"]: "Product ID not found.",
                     },
                  ],
               },
            });
         }

         const response = await ProductImage.getProductImagesByProductId(this._id);

         if (response instanceof ModelError) {
            return response;
         }

         this.__images = response;

         return this.__images;
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    * Get all product variants
    * @param renew
    * @returns
    */
   public async getVariants(renew: boolean = false) {
      try {
         if (!renew && this.__variants) {
            return this.__variants;
         }

         if (!this._id) {
            return new ModelError({
               ["httpCode"]: 400,
               ["message"]: "Product instance only exists on client.",
               ["errors"]: {
                  ["_id"]: [
                     {
                        ["code"]: "product.getVariants._id.notFound",
                        ["message"]: "Product ID not found.",
                     },
                  ],
               },
            });
         }

         const response = await ProductVariant.getProductVariantsByProductId(this._id);

         if (response instanceof ModelError) {
            return response;
         }

         this.__variants = response;

         return this.__variants;
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   /**
    * Get all product variants
    * @param renew
    * @returns
    */
   public async getOptions(renew: boolean = false) {
      try {
         if (!renew && this.__options) {
            return this.__options;
         }

         if (!this._id) {
            return new ModelError({
               ["httpCode"]: 400,
               ["message"]: "Product instance only exists on client.",
               ["errors"]: {
                  ["_id"]: [
                     {
                        ["code"]: "product.getVariants._id.notFound",
                        ["message"]: "Product ID not found.",
                     },
                  ],
               },
            });
         }

         const response = await ProductOption.getProductOptionById(this._id);

         if (response instanceof ModelError) {
            return response;
         }

         this.__options = response;

         return this.__options;
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public async getVendor(renew: boolean = false) {
      try {
         if (!renew && this.__vendor) {
            return this.__vendor;
         }

         if (this.vendorId === undefined) {
            return new ModelError({
               ["httpCode"]: 400,
               ["message"]: "Vendor ID don't exists inside an instance.",
               ["errors"]: {
                  ["_id"]: [
                     {
                        ["code"]: "product.getVendor.vendorId.notFound",
                        ["message"]: "Vendor ID not found.",
                     },
                  ],
               },
            });
         }

         if (this.vendorId === null) {
            this.__vendor = null;
            return this.__vendor;
         }
         const response = await Vendor.getVendorById(this.vendorId);

         if (response instanceof ModelError) {
            return response;
         }

         this.__vendor = response;

         return this.__vendor;
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }

   public static async getAllProducts({ page, limit }: { page?: number | string; limit?: number | string }) {
      try {
         // const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/general/product-slugs`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(page ? { page: String(page) } : undefined),
               ...(limit ? { limit: String(limit) } : undefined),
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();
         const paginate = payload?.data;
         return { paginate };
      } catch (error) {}
   }
   public static async getAllCategory() {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const response = await fetch(`${apiDomain}/www/general/product-menu`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
            },
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }
         const payload = await response.json();
         const paginate = new Paginate(payload.data, Product);

         return { paginate };
      } catch (error) {}
   }

   public static async suggestions({
      token,
      id,
      page,
      limit,
      sort,
      sortBy,
   }: {
      token?: string;
      id: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: string;
   }) {
      try {
         const res = await Model.fetchData({
            token,
            ["method"]: "GET",
            ["endPoint"]: "product/suggestions?offcontent=true",
            ["headers"]: {
               ["id"]: id,
               ...(page ? { page: String(page) } : undefined),
               ...(limit ? { limit: String(limit) } : undefined),
               ...(sort ? { sort: String(sort) } : undefined),
               ...(sortBy ? { "sort-by": String(sortBy) } : undefined),
            },
         });

         // Nếu res là ModelError, trả về ngay
         if (res instanceof ModelError) {
            return res;
         }

         // Kiểm tra lỗi HTTP thông qua handleSSRResponseError
         await handleSSRResponseError(res);

         // Đọc response dưới dạng text trước khi parse JSON
         const rawText = await res.text();

         if (!rawText.trim()) {
            throw new ModelError({ message: "Empty response from server.", httpCode: 500 });
         }

         // Kiểm tra nếu phản hồi là HTML (có thể do lỗi server)
         if (rawText.trim().startsWith("<")) {
            throw new ModelError({ message: `Unexpected HTML response: ${rawText.slice(0, 100)}...`, httpCode: 500 });
         }

         // Parse JSON một cách an toàn
         let payload: TPayload<{ suggestion: TPaginate<Product> }>;

         try {
            payload = JSON.parse(rawText);
         } catch {
            throw new ModelError({ message: "Invalid JSON response from API.", httpCode: 500 });
         }

         // Kiểm tra dữ liệu trả về có hợp lệ không
         if (!payload?.data?.suggestion) {
            throw new ModelError({ message: "Invalid response structure: Missing 'suggestion'.", httpCode: 500 });
         }

         // Trả về dữ liệu đã kiểm tra
         return { paginate: new Paginate(payload.data.suggestion, Product) };
      } catch (error) {
         return new ModelError(error);
      }
   }

   public static async validateSlug(slug: string) {
      if (!slug) return { isCategory: false, isProduct: false };
      try {
         const response = await fetch(`${apiDomain}/www/product/validate-slug`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["slug"]: encodeURI(slug),
            },
         });

         if (!response.ok) {
            try {
               return new ModelError((await response.json()) as TModelError);
            } catch {
               throw new Error(`Invalid error response: ${await response.text()}`);
            }
         }
         const rawText = await response.text();
         if (rawText.trim().startsWith("<")) {
            throw new Error(`Unexpected HTML response: ${rawText.slice(0, 100)}...`);
         }
         try {
            const payload = JSON.parse(rawText) as TPayload<any>;
            return payload.data;
         } catch {
            throw new Error(`Invalid JSON response: ${rawText}`);
         }
      } catch (error) {}
   }

   public static async redirection(url: string, type?: string) {
      try {
         const response = await fetch(`${apiDomain}/www/redirection`, {
            ["method"]: "GET",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["url"]: encodeURI(url),
               ["type"]: type ? encodeURI(type) : "shop",
            },
         });
         // Kiểm tra nếu request thất bại
         if (!response.ok) {
            try {
               return new ModelError((await response.json()) as TModelError);
            } catch {
               throw new Error(`Invalid error response: ${await response.text()}`);
            }
         }

         // Đọc phản hồi dưới dạng văn bản trước khi parse JSON
         const rawText = await response.text();

         // Kiểm tra nếu phản hồi là HTML (lỗi từ server)
         if (rawText.trim().startsWith("<")) {
            throw new Error(`Unexpected HTML response: ${rawText.slice(0, 100)}...`);
         }

         // Parse JSON một cách an toàn
         try {
            const payload = JSON.parse(rawText);
            return payload?.data ?? null;
         } catch {
            throw new Error(`Invalid JSON response: ${rawText}`);
         }
      } catch (error) {}
   }
}
export default Product;
