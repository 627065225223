import { useState, useEffect } from "react";

export enum EScreenSize {
   MOBILE = "mobile",
   TABLET = "tablet",
   DESKTOP = "desktop",
}

const useResponsive = () => {
   const [screenSize, setScreenSize] = useState<EScreenSize>(EScreenSize.DESKTOP);

   useEffect(() => {
      const handleResize = () => {
         const width = window.innerWidth;

         if (width < 768) {
            setScreenSize(EScreenSize.MOBILE);
         } else if (width >= 768 && width < 1024) {
            setScreenSize(EScreenSize.TABLET);
         } else {
            setScreenSize(EScreenSize.DESKTOP);
         }
      };

      // Gọi một lần khi hook được mount
      handleResize();

      // Lắng nghe sự thay đổi kích thước cửa sổ
      window.addEventListener("resize", handleResize);

      // Cleanup khi component unmount
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   return screenSize;
};

export default useResponsive;
