import { FunctionComponent, memo, useEffect } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "state";
import styles from "./index.module.scss";
import Loadingv2 from "./v2";

interface LoadingProps {
   loading?: boolean;
}

const Loading: FunctionComponent<LoadingProps> = (props: any) => {
   const { loading } = useSelector((state: RootState) => state.global);

   const loadingClassName = classnames([
      styles.globalLoading,
      {
         [styles.active]: loading,
      },
   ]);

   useEffect(() => {
      if (loading) {
         document.body.classList.add("overflow-hidden");
      } else {
         document.body.classList.remove("overflow-hidden");
      }
   }, [loading]);

   if (!loading) return <></>;
   return (
      <div className={loadingClassName}>
         <Loadingv2 />
      </div>
   );
};

export default memo<LoadingProps>(Loading);
